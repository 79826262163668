import React, { useContext, useEffect, useState } from "react";
import { useGetOrderByUserIdQuery } from "../../services/orderApi";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { UserContext } from "../../App";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const MyOrders = () => {
  const [currentTab, setCurrentTab] = useState("succeeded");
  const { user } = useContext(UserContext);
  const { data } = useGetOrderByUserIdQuery(user?._id);
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="max-w-[1440px] mx-auto min-h-[60vh]">
      <div className="w-[100%] my-[20px]">
        <p className="text-[16px] font-bold">Home {">"} My Orders</p>
      </div>
      <div>
        <p className="text-[24px] font-bold my-[30px]">My Orders</p>
        <div className="w-[100%] flex items-center text-center mb-[15px] border-b-[2px] gap-[10%]">
          <p
            onClick={() => setCurrentTab("succeeded")}
            className={
              currentTab === "succeeded"
                ? "text-[20px]  border-b-[2px] border-black w-[30%] cursor-pointer"
                : "text-[20px] w-[30%] cursor-pointer"
            }
          >
            Active
          </p>
          <p
            onClick={() => setCurrentTab("canceled")}
            className={
              currentTab === "canceled"
                ? "text-[20px]  border-b-[2px] border-black w-[30%] cursor-pointer"
                : "text-[20px]  w-[30%] cursor-pointer"
            }
          >
            Canceled
          </p>
          <p
            onClick={() => setCurrentTab("shipped")}
            className={
              currentTab === "shipped"
                ? "text-[20px]  border-b-[2px] border-black w-[30%] cursor-pointer"
                : "text-[20px] w-[30%] cursor-pointer"
            }
          >
            Delivered
          </p>
        </div>
        <div className="py-[30px]">
          {data?.data?.map((d) => (
            <>
              {d?.status === currentTab && (
                <Accordion
                  expanded={expanded === d?._id}
                  onChange={handleChange(d?._id)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className="w-[100%]"
                  >
                    <Typography className="w-[100%]">
                      <div className="w-[100%] flex justify-between items-center">
                        <p>Order Id: {d?._id}</p>
                        <p>{d?.status === "succeeded" && "Paid"}</p>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {d?.products?.map((c) => (
                      <div className="flex flex-col py-1 px-[7px] border-t border-b">
                        <div className="flex items-start space-x-4">
                          <img
                            src={c?.images[0]}
                            alt="Item"
                            className="w-[70px] h-[70px] object-cover"
                          />
                          <div className="flex flex-col flex-grow ">
                            <p className="text-[14px] my-[14px]">
                              {" "}
                              {c?.count} x {c?.name}
                            </p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-[22px] my-[14px]">£{c?.price}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
