import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/orders`,
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    orders: builder.query({
      query: () => `/`,
      providesTags: ["Order"],
    }),
    getOrderById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Order"],
    }),
    getOrderByUserId: builder.query({
      query: (id) => `/userId/${id}`,
      providesTags: ["Order"],
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Order"],
    }),
    makePayment: builder.mutation({
      query: (data) => ({
        url: "/create-payment-intent",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Order"],
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Order"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});
export const {
  useOrdersQuery,
  useGetOrderByIdQuery,
  useMakePaymentMutation,
  useAddOrderMutation,
  useGetOrderByUserIdQuery,
} = orderApi;
