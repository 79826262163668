import React, { useState } from "react";

function ProductDescription({ description }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const maxLength = 285; // Define the maximum length of the text

  return (
    <div className="w-[100%] lg:w-[70%]">
      <p
        className={`mt-4 my-4 text-[13px] ${showMore ? "" : " md:h-[5em] text-ellipsis"}`}
        id="productDescription"
      >
        {description &&
          (showMore ? description : description.slice(0, maxLength))}
        {!showMore && description && description.length > maxLength && "..."}
        {!showMore && description && description.length > maxLength && (
          <button
            onClick={toggleShowMore}
            className="text-[#DB4444] hover:underline"
          >
            See More
          </button>
        )}
        {showMore && (
        <button
          onClick={toggleShowMore}
          className="text-[#DB4444] hover:underline pl-2"
        >
          See Less
        </button>
      )}
      </p>

      
    </div>
  );
}

export default ProductDescription;
