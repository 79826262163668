import { useContext, useEffect, useState } from "react";
import { useAddOrderMutation } from "../../services/orderApi";
import { useNavigate } from "react-router-dom";
import CheckedIcon from "../../assets/Icons/svg/icon-checked-sign.svg";
import { UserContext } from "../../App";
import { useDispatch } from "react-redux";
import { setItems } from "../../state";
function Success(props) {
  const [messageBody, setMessageBody] = useState("");
  const { stripePromise } = props;
  const [message, setMessage] = useState("");
  const [addOrder] = useAddOrderMutation();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const data = JSON.parse(localStorage.getItem("paymentDetails"));
      const couponDetails = JSON.parse(localStorage.getItem("price"));

      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );
      await stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case "succeeded":
              const d = {
                orderBy: data?.userId,
                products: data?.products,
                clientSecret: paymentIntent?.client_secret,
                txId: paymentIntent?.id,

                billing: data?.billing,
                shipping: paymentIntent?.shipping,

                amount: paymentIntent?.amount / 100,
                currency: paymentIntent?.currency,
                created: paymentIntent?.created,
                status: paymentIntent?.status,
                couponDetails: couponDetails?.couponDetails,
              };
              addOrder(d).then((res) => {
                if (res.data?.status) {
                  localStorage.removeItem("price");
                  localStorage.removeItem("shippingDetails");
                  localStorage.removeItem("paymentDetails");
                  localStorage.removeItem("cart");
                  dispatch(setItems([]));
                  setMessageBody(
                    error ? (
                      `> ${error.message}`
                    ) : (
                      <>
                        &gt; Payment {paymentIntent.status}:{" "}
                        <a
                          href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {paymentIntent.id}
                        </a>
                      </>
                    )
                  );
                }
              });
              break;
            case "processing":
              break;
            case "requires payment method":
              break;

            default:
              setMessage("Something Went Wrong");
              break;
          }
        });
    });
  }, [stripePromise]);

  return (
    <>
      <div className=" flex items-center justify-center mx-auto bg-[#f7f7f7] h-[60vh]">
        {/* Modal Content */}
        <div className="bg-white rounded-lg w-[400px] flex flex-col items-center justify-center py-[20px]">
          {/* Modal Content */}
          <img className="h-[90px] w-[90px] mx-auto" src={CheckedIcon} alt="" />
          <p className="font-semibold mt-[20px] text-[24px] text-center">
            Thank You!
          </p>
          <p className="font-semibold mt-[8px] mb-[12px] text-center">
            Your Order has been Placed.
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="art-bg-primary text-white px-[35px] py-[8px] rounded mt-[15px]"
          >
            Go Back to Home
          </button>
        </div>
      </div>
    </>
  );
}

export default Success;
