import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AboutUs from "./Pages/AboutUs/AboutUs";
import CartPage from "./Pages/CartPage/CartPage";
import Category from "./Pages/Category/Category";
import Success from "./Pages/Checkout/Success";
import Home from "./Pages/Home/Home";
import MyOrders from "./Pages/MyOrders/MyOrders";
import CheckoutForm from "./Pages/PaymentPage/CheckoutForm";
import PaymentPage from "./Pages/PaymentPage/PaymentPage";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import Footer from "./Pages/Shared/Footer/Footer";
import Navbar from "./Pages/Shared/Navbar/Navbar";
import { baseUrl } from "./services/api";

export const UserContext = createContext();
function App() {
  const [user, setUser] = useState(null);
  const [refetch, setRefetch] = useState(0);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get(baseUrl + "/user/autoLogin", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res?.data?.result);
          setUser(res?.data?.result);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [refetch]);
  return (
    <div>
      <UserContext.Provider
        value={{ user, setUser, refetch, setRefetch, loading }}
      >
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/cart" element={<CartPage></CartPage>}></Route>
          <Route path="/my-orders" element={<MyOrders></MyOrders>}></Route>
          <Route
            path="/product-details/:id"
            element={<ProductDetails></ProductDetails>}
          ></Route>
          <Route path="/categories/:id" element={<Category></Category>}></Route>
          <Route path="/payment" element={<PaymentPage></PaymentPage>}></Route>
          <Route
            path="/checkout/:id"
            element={
              <CheckoutForm stripePromise={stripePromise}></CheckoutForm>
            }
          ></Route>
          <Route
            path="/success"
            element={<Success stripePromise={stripePromise}></Success>}
          ></Route>
          <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
        </Routes>
        <Footer></Footer>
      </UserContext.Provider>
      <ToastContainer />
    </div>
  );
}

export default App;
