import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useGetCatProductsQuery } from "../../../../services/productApi";
import { changeValueSub } from "../../../../state";
import CardComponent from "../CardComponent/CardComponent";

const FilterCategory = ({
  data,
  from,
  homeData,
  isHomeLoading,
  newProductLoading,
  newProducts,
  featuredProduct, featuredLoading,
  value, setValue
}) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch()
  const valueSub = useSelector((state) => state.cart.valueSub);

  const breakPoint = useMediaQuery("(min-width:600px");
  const { data: categoryData, isLoading } = useGetCatProductsQuery({
    cat: data?.value,
    subCat: valueSub,
    page: page,
    limit: 8,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
  };
  const handleSubCat = (event, newValue) => {
    dispatch(changeValueSub((newValue)))
    setPage(1);
  };
  useEffect(() => {
    setPage(1);
  }, [data?.value]);
  console.log(featuredProduct, featuredLoading)
  return (
    <div className="max-w-[1440px] mx-auto">
      <Box width="100%">
        {from === "featured" ? (
          <>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
              centered
              TabIndicatorProps={{
                sx: { display: breakPoint ? "block" : "none" },
              }}
              sx={{
                m: "25px",
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              {" "}
              <Tab label="FEATURED" value="featured" />
              <Tab label="PRODUCTS" value="products" />
              {/* <Tab label="NEW ARRIVALS" value="newArrivals" /> */}
            </Tabs>

            {/* featured */}

            {value === "featured" && (
              <>
                {featuredLoading ? (
                  <div className="w-[100%] flex justify-center items-center min-h-[300px]">
                    <Oval
                      height={80}
                      width={80}
                      color="#DF5645"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#DF5645"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  </div>
                ) : (
                  <>
                    {featuredProduct?.length === 0 ? (
                      <div className="h-[200px] flex justify-center items-center">
                        <h3 className="text-red-600 text-[21px]">
                          No Product Available!
                        </h3>
                      </div>
                    ) : (
                      <CardComponent from={"featured"} data={featuredProduct} />
                    )}
                  </>
                )}
              </>
            )}

            {value === "products" && (
              <>
                {isHomeLoading ? (
                  <div className="w-[100%] flex justify-center items-center min-h-[300px]">
                    <Oval
                      height={80}
                      width={80}
                      color="#DF5645"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#DF5645"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  </div>
                ) : (
                  <>
                    {homeData?.length === 0 ? (
                      <div className="h-[200px] flex justify-center items-center">
                        <h3 className="text-red-600 text-[21px]">
                          No Product Available!
                        </h3>
                      </div>
                    ) : (
                      <CardComponent data={homeData} />
                    )}
                  </>
                )}
              </>
            )}

            {/* new Arrivals */}
            {/* {value === "newArrivals" && (
              <>
                {newProductLoading ? (
                  <div className="w-[100%] flex justify-center items-center min-h-[300px]">
                    <Oval
                      height={80}
                      width={80}
                      color="#DF5645"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#DF5645"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  </div>
                ) : (
                  <>
                    {newProducts?.length === 0 ? (
                      <div className="h-[200px] flex justify-center items-center">
                        <h3 className="text-red-600 text-[21px]">
                          No Product Available!
                        </h3>
                      </div>
                    ) : (
                      <CardComponent data={newProducts} />
                    )}
                  </>
                )}
              </>
            )} */}
          </>
        ) : (
          <>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={valueSub}
              onChange={handleSubCat}
              centered
              TabIndicatorProps={{
                sx: { display: breakPoint ? "block" : "none" },
              }}
              sx={{
                m: "25px",
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Tab label={"All"} value={"all"} />
              {data?.subCategories?.map((s) => (
                <Tab label={s} value={s} />
              ))}
            </Tabs>
            {isLoading ? (
              <div className="w-[100%] flex justify-center items-center min-h-[300px]">
                <Oval
                  height={80}
                  width={80}
                  color="#DF5645"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#DF5645"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </div>
            ) : (
              <>
                {categoryData?.data?.length === 0 ? (
                  <div className="h-[200px] flex justify-center items-center">
                    <h3 className="text-red-600 text-[21px]">
                      No Product Available!
                    </h3>
                  </div>
                ) : (
                  <CardComponent data={categoryData?.data} />
                )}
              </>
            )}
            <>
              <div className="max-w-[1440px] mt-[20px] flex flex-col-reverse lg:flex-row justify-between items-center lg:justify-between gap-[20px] mx-auto lg:w-[96%]">
                <div>
                  Page {page} of {categoryData?.pagination?.totalPages}
                </div>
                <div>Total {categoryData?.pagination?.totalItems} Items</div>
                <div className="flex items-center">
                  {page > 1 ? (
                    <button
                      onClick={() => setPage(page - 1)}
                      className="text-[#DF5645] border-[1px] border-[#DF5645] px-[15px] py-[6px] w-[80px] rounded font-bold mr-[10px] hover:bg-[#DF5645]  hover:text-white flex justify-center items-center"
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      disabled
                      className="text-[grey] border-[1px] border-[grey] px-[15px] py-[6px] w-[80px] rounded font-bold mr-[10px] flex justify-center items-center"
                    >
                      Previous
                    </button>
                  )}
                  {page < categoryData?.pagination?.totalPages ? (
                    <button
                      onClick={() => setPage(page + 1)}
                      className="text-[#DF5645] border-[1px] border-[#DF5645] px-[15px] py-[6px] w-[80px] rounded font-bold ml-[10px] hover:bg-[#DF5645]  hover:text-white flex justify-center items-center"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      disabled
                      className="text-[grey] border-[1px] border-[grey] px-[15px] py-[6px] w-[80px] rounded font-bold ml-[10px] flex justify-center items-center"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </>
          </>
        )}
      </Box>
    </div>
  );
};

export default FilterCategory;
