// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import slider2 from "../../../assets/slider2.svg";

// Import required modules
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
const Banner = () => {
  const bannerData = ["/banner1.svg"];
  return (
    <>
      {/* <Swiper
        navigation={true}
        autoplay={{ delay: 8000, disableOnInteraction: false }} // Slide changes every 3 seconds
        loop={true} // Enables continuous loop mode
        speed={2000} // Adjusts the transition speed (1000ms = 1 second)
        modules={[Navigation, Autoplay]}
        className="mySwiper"
      >
        {bannerData?.map((data, i) => (
          <SwiperSlide key={i}>
            <img
              src={`${data}`}
              alt="banner"
              className="w-[100%] mb-[50px] lg:mb-[50px] xl:mb-[70px]"
            />
          </SwiperSlide>
        ))}
      </Swiper> */}
      <img
        src={`/banner1.svg`}
        alt="banner"
        className="w-[100%] mb-[50px] lg:mb-[50px] xl:mb-[70px]"
      />
    </>
  );
};

export default Banner;
