import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
export const featuredApi = createApi({
  reducerPath: "featuredApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/featured`,
  }),
  tagTypes: ["Featured"],
  endpoints: (builder) => ({
    featureds: builder.query({
      query: () => `/`,
      providesTags: ["Featured"],
    }),
    getAllProducts: builder.query({
      query: () => `/products`,
      providesTags: ["Featured"],
    }),
    addFeatured: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Featured"],
    }),
    deleteFeatured: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Featured"],
    }),
  }),
});
export const {
  useFeaturedsQuery,
  useAddFeaturedMutation,
  useDeleteFeaturedMutation,
  useGetAllProductsQuery,
} = featuredApi;
