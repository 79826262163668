const depopReviews = [
  {
    type: "depop",
    date: "2023-11-04T01:50:27.919097Z",
    rating: 5,
    text: "Quick shipping and sound",
    transaction_id: 157823127,
    product: {
      id: 370145306,
      preview: {
        150: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P2.jpg",
        210: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P4.jpg",
        320: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P5.jpg",
        480: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P6.jpg",
        640: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P1.jpg",
        960: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P7.jpg",
        1280: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P8.jpg",
      },
    },
    author: {
      id: 26061002,
      verified: false,
      username: "lil_ramen_dump",
    },
  },
  {
    date: "2023-10-16T01:29:38.153601Z",
    type: "depop",
    rating: 5,
    text: "Excellent service with my purchase. Very responsive and shipped internationally with no issues. Fantastic product also and as described. Definitely worthwhile of a 5 star review for the Emporium Inn. Cheers Mark",
    transaction_id: 155996620,
    product: {
      id: 378004069,
      preview: {
        150: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P2.jpg",
        210: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P4.jpg",
        320: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P5.jpg",
        480: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P6.jpg",
        640: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P1.jpg",
        960: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P7.jpg",
        1280: "https://media-photos.depop.com/b1/17566244/1611555448_3599f1bc9b3a48698f1f17fd2f4f0e9d/P8.jpg",
      },
    },
    author: {
      id: 43710144,
      verified: false,
      username: "dumpme",
    },
  },
  {
    type: "depop",
    date: "2023-09-11T20:59:33.284986Z",
    rating: 5,
    text: "Very happy with purchase and buying experience 😊 ",
    transaction_id: 153133009,
    product: {
      id: 370135240,
      preview: {
        150: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P2.jpg",
        210: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P4.jpg",
        320: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P5.jpg",
        480: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P6.jpg",
        640: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P1.jpg",
        960: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P7.jpg",
        1280: "https://media-photos.depop.com/b1/17566244/1576322677_9031b24063954eb4a5df0eebd9907567/P8.jpg",
      },
    },
    author: {
      id: 30884456,
      verified: false,
      username: "municipal3",
    },
  },
  {
    type: "depop",
    date: "2023-08-17T10:54:05.072201Z",
    rating: 5,
    text: "Came really quickly and good condition",
    transaction_id: 151675247,
    product: {
      id: 370145306,
      preview: {
        150: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P2.jpg",
        210: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P4.jpg",
        320: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P5.jpg",
        480: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P6.jpg",
        640: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P1.jpg",
        960: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P7.jpg",
        1280: "https://media-photos.depop.com/b1/17566244/1576368546_43cb2f325abc414bbe004c600a6b414e/P8.jpg",
      },
    },
    author: {
      id: 16330201,
      verified: false,
      username: "jlcampbell0520",
    },
  },
  {
    type: "depop",
    date: "2021-12-23T13:41:26.622515Z",
    rating: 5,
    text: "Wow!!! Got my product so quickly and looks exactly like the pic! Good communication I’m so impressed! Tysm!",
    transaction_id: 111970186,
    product: {
      id: 270900888,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/1131728314_ce92ee99526e4f1e8e48ae40f277c330/P8.jpg",
      },
    },
    author: {
      id: 33676853,
      verified: false,
    },
  },
  {
    type: "depop",
    date: "2021-12-09T21:32:09.836604Z",
    rating: 5,
    text: "Perfect! Thank you x",
    transaction_id: 110433395,
    product: {
      id: 270418212,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/1129971943_12b516be87724c24830ece97c9ffea57/P8.jpg",
      },
    },
    author: {
      id: 14247009,
      verified: false,
      username: "aleeshaflynnx",
    },
  },
  {
    type: "depop",
    date: "2021-11-24T15:37:33.494044Z",
    rating: 5,
    text: "Item arrived as described and pictured. 10/10 seller 👏🏼",
    transaction_id: 109619700,
    product: {
      id: 268748097,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/1123798702_09694cd5f5d74223bf1e87c050d2b754/P8.jpg",
      },
    },
    author: {
      id: 5982664,
      verified: false,
    },
  },
  {
    type: "depop",
    date: "2021-06-24T19:03:58.562Z",
    rating: 5,
    text: "Really impressed thank you !!",
    transaction_id: 95491798,
    product: {
      id: 233952387,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/997003918_09047513b0f841f78467f494c72b7086/P8.jpg",
      },
    },
    author: {
      id: 3232227,
      verified: false,
    },
  },
  {
    type: "depop",
    date: "2021-03-26T09:04:48.792Z",
    rating: 5,
    text: "Fast delivery very happy",
    transaction_id: 87616386,
    product: {
      id: 217405830,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/936518422_e27713c2f6dc4e49b80a0efed67466cc/P8.jpg",
      },
    },
    author: {
      id: 16089042,
      verified: false,
      username: "bc_157",
    },
  },
  {
    type: "depop",
    date: "2020-06-10T14:46:36.243Z",
    rating: 5,
    text: "It’s great. Tysm!",
    transaction_id: 54995882,
    product: {
      id: 148571597,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/663599971_9c51b7a98379405c84e50543c93721fd/P8.jpg",
      },
    },
    author: {
      id: 15742168,
      verified: false,
      username: "nichiinya",
    },
  },
  {
    type: "depop",
    date: "2020-06-01T12:47:05.535Z",
    rating: 5,
    text: "10/10 everything was perfect, such a lovely and professional seller. definitely will be buying here again. communication and postage was flawless. i recommend!! thank you so much ♥️",
    transaction_id: 53274185,
    product: {
      id: 154391871,
      preview: {
        150: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P2.jpg",
        210: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P4.jpg",
        320: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P5.jpg",
        480: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P6.jpg",
        640: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P1.jpg",
        960: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P7.jpg",
        1280: "https://media-photos.depop.com/b0/17566244/699156606_2a52635fd3234dba85744ef2c5427d82/P8.jpg",
      },
    },
    author: {
      id: 12938516,
      verified: false,
      username: "cheapbuys68",
    },
  },
];

export default depopReviews;