import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  decreaseClothesCount,
  decreaseCount,
  increaseClothesCountCart,
  increaseCount,
  removeFromCart,
  removeFromWishList
} from "../../../../../state";

const Items = ({ c, from, setWishListDrop }) => {
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();
  const wishList = useSelector((state) => state.cart.wishList);
  const dispatch = useDispatch();
  const handleRemove = (c) => {
    dispatch(removeFromCart(c));
    const removed = cart?.filter((w) => w?._id !== c?._id);
    localStorage.setItem("cart", JSON.stringify(removed));
  };
  const handleRemoveWishList = (c) => {
    dispatch(removeFromWishList(c));
    const removed = wishList?.filter((w) => w?._id !== c?._id);
    localStorage.setItem("wishlist", JSON.stringify(removed));
  };
  return (
    <div className="flex flex-col py-1 border-t border-b">
      <div className="flex items-center space-x-4">
        {c?.images?.length > 0 && (
          <img
            src={c?.images[0]}
            alt="Item"
            className="w-[51px] h-[70px] object-cover"
          />
        )}
        <div className="flex flex-col flex-grow ">
          <p className="text-[14px] mb-[4px]">{c?.name}</p>
          {from === "cart" ? (
            <p
              onClick={() => handleRemove(c)}
              className="text-[14px] font-semibold cursor-pointer text-red-700"
            >
              REMOVE
            </p>
          ) : (
            <p
              onClick={() => handleRemoveWishList(c)}
              className="text-[14px] font-semibold cursor-pointer text-red-700"
            >
              REMOVE
            </p>
          )}
        </div>

        <div className="flex flex-col">
          {from === "cart" && (
            <>
              {c?.category === "fashion" && c?.subCategory === "clothes" ? (
                <p className="text-[18px] ">
                  <span
                    className="cursor-pointer text-[26px]"
                    onClick={() => dispatch(decreaseClothesCount(c))}
                  >
                    -
                  </span>{" "}
                  {c?.counter?.quantity}{" "}
                  <span
                    className="cursor-pointer text-[20px]"
                    onClick={() => dispatch(increaseClothesCountCart(c))}
                  >
                    +
                  </span>
                </p>
              ) : (
                <p className="text-[18px] ">
                  {c?.quantity > 0 && (
                    <span
                      className="cursor-pointer text-[26px]"
                      onClick={() => dispatch(decreaseCount(c))}
                    >
                      -
                    </span>
                  )}{" "}
                  {c?.count}{" "}
                  {c?.count < c?.quantity ? (
                    <span
                      className="cursor-pointer text-[20px]"
                      onClick={() => dispatch(increaseCount(c))}
                    >
                      +
                    </span>
                  ) : (
                    <span className="cursor-pointer text-[20px]">+</span>
                  )}
                </p>
              )}
            </>
          )}

          <p className="text-[22px] ">£{c?.price}</p>
          {from === "wishlist" && (
            <p className="text-center">
              <span
                className="text-[14px] text-[blue] hover:underline cursor-pointer"
                onClick={() => {
                  navigate(`/product-details/${c?._id}`);
                  setWishListDrop(false);
                }}
              >
                View
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Items;
