import React from "react";
import ProductSlider from "./ProductSlider";

const AlsoProducts = ({cat}) => {
  return (
    <div className="pb-[30px] md:pb-[80px]">
      <p className="font-medium text-[21px] mb-[30px] 2xl:mb-[50px] ml-3">
        Also You May Like
      </p>
      <ProductSlider cat={cat} />
    </div>
  );
};

export default AlsoProducts;
