import React, { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useCategoriesQuery } from "../../../../services/categoryApi";
import { useProductsQuery } from "../../../../services/productApi";
import { changeValueSub, updateCategory } from "../../../../state";

const Menu = ({
  isCategoryMenuOpen,
  setIsCategoryMenuOpen,
  setIsOpenProfile,
  setIsOpenLogin,
  setIsRegisterOpen,
  setIsOpenCart,
  setIsOpenLoginModal,
  closeNav,
}) => {
  const [isSubCatOpen, setIsSubCatOpen] = useState({});
  const [isProductOpen, setIsProductOpen] = useState(false);

  const toggleDropdown = () => {
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
    setIsOpenProfile(false);
    setIsOpenCart(false);
    setIsRegisterOpen(false);
    setIsOpenLoginModal(false);
  };
  const cat = useSelector((state) => state.cart.category);
  const dispatch = useDispatch();
  const { data } = useCategoriesQuery();
  const { refetch } = useProductsQuery({ cat: cat });
  const navigate = useNavigate();
  const handleClickCat = (menuItem) => {
    refetch();
    dispatch(changeValueSub("all"));
    dispatch(updateCategory(menuItem?.value));
    if (!menuItem?.subCategory) {
      navigate(`/categories/${menuItem?.value}`);
      setIsCategoryMenuOpen(false);
      closeNav();
    }
    if (menuItem?.value === isSubCatOpen) {
      setIsSubCatOpen("");
    } else {
      setIsSubCatOpen(menuItem?.value);
    }
  };
  return (
    <>
      <div className="hidden lg:flex justify-center items-center gap-4">
        <div className="relative inline-block text-left">
          <p
            type="button"
            className="group rounded-md p-2 flex items-center text-base font-medium text-gray-700 hover:text-black cursor-pointer text-[16px]"
            onClick={toggleDropdown}
          >
            Products
          </p>

          {isCategoryMenuOpen && (
            <div
              className="origin-top-right top-[30px] lg:top-[35px] absolute left-0 lg:right-0 mt-2 w-56 rounded shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[14px]"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="py-1" role="none">
                {data?.data?.map((menuItem, index) => (
                  <span
                    key={menuItem._id}
                    href="#"
                    className="flex flex-row justify-between items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer uppercase rounded"
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${index + 1}`}
                    onClick={() => {
                      refetch();
                      dispatch(updateCategory(menuItem?.value));
                      navigate(`/categories/${menuItem?.value}`);
                      setIsCategoryMenuOpen(false);
                      closeNav();
                    }}
                  >
                    {menuItem?.name} {menuItem.isSubmenu && <FaAngleRight />}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        <p
          onClick={() => {
            navigate("/about-us");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md p-2 inline-flex items-center text-base font-medium text-gray-700 hover:text-black cursor-pointer text-[16px]"
        >
          About us
        </p>

        <HashLink
          to="/#contactus"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            // navigate("/#contactus");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md p-2 inline-flex items-center text-base font-medium text-gray-700 hover:text-black cursor-pointer text-[16px]"
        >
          Contact us
        </HashLink>
        <HashLink
          to="/#testimonials"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md p-2 inline-flex items-center text-base font-medium text-gray-700 hover:text-black cursor-pointer text-[16px]"
        >
          Client Testimonials
        </HashLink>

        <HashLink
          to="/#buyitems"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            // navigate("/#contactus");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md p-2 inline-flex items-center text-base font-medium text-gray-700 hover:text-black cursor-pointer text-[16px]"
        >
          We buy your items
        </HashLink>
      </div>

      <div className="flex lg:hidden flex-col md:flex-row md:gap-[30px] 2xl:gap-[85px] md:ml-[40px] 2xl:ml-[86px] md:mr-[30px] 2xl:mr-[71px] mt-10 md:mt-0">
        <p
          onClick={() => {
            navigate("/about-us");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md px-[6px] py-[3px] inline-flex items-center font-bold text-gray-700 hover:text-black cursor-pointer text-[14px] my-[8px] uppercase"
        >
          About us
        </p>
        <div
          onClick={() => setIsProductOpen(!isProductOpen)}
          className="flex justify-between  px-[6px] py-[3px] items-center w-[100%] font-bold  text-[14px]  my-[8px] uppercase"
        >
          <div>Products</div>
          <div> {isProductOpen ? <FaAngleDown /> : <FaAngleRight />}</div>
        </div>
        {isProductOpen && (
          <div className="relative inline-block text-left ml-[10px]">
            {data?.data?.map((menuItem, index) => (
              <div className="group rounded-md px-[6px] py-[5px]  text-gray-700 hover:text-black cursor-pointer">
                <div
                  onClick={() => handleClickCat(menuItem)}
                  className="flex justify-between items-center w-[100%] font-bold  text-[14px]  my-[8px] uppercase"
                >
                  <div>{menuItem?.name}</div>
                  <div>
                    {" "}
                    {menuItem.subCategory && (
                      <>
                        {menuItem?.value === isSubCatOpen ? (
                          <FaAngleDown />
                        ) : (
                          <FaAngleRight />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {menuItem?.value === isSubCatOpen && (
                  <>
                    {menuItem?.subCategories?.map((m) => (
                      <p
                        onClick={() => {
                          dispatch(changeValueSub(m));
                          navigate(`/categories/${menuItem?.value}`);
                          setIsCategoryMenuOpen(false);
                          closeNav();
                        }}
                        type="button"
                        className="group rounded-md px-[8px] py-[3px] items-center font-bold  text-gray-700 hover:text-black cursor-pointer text-[14px]  my-[8px]  uppercase"
                      >
                        {m}
                      </p>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>
        )}

        <HashLink
          to="/#contactus"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            // navigate("/#contactus");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md px-[6px] py-[3px] inline-flex items-center font-bold text-gray-700 hover:text-black cursor-pointer text-[14px] my-[8px] uppercase"
        >
          Contact us
        </HashLink>
        <HashLink
          to="/#testimonials"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            // navigate("/#contactus");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md px-[6px] py-[3px] inline-flex items-center font-bold text-gray-700 hover:text-black cursor-pointer text-[14px] my-[8px] uppercase"
        >
          Client Testimonials
        </HashLink>

        <HashLink
          to="/#buyitems"
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          onClick={() => {
            // navigate("/#contactus");
            closeNav();
            setIsCategoryMenuOpen(false);
          }}
          className="group rounded-md px-[6px] py-[3px] inline-flex items-center font-bold text-gray-700 hover:text-black cursor-pointer text-[14px] my-[8px] uppercase"
        >
          We buy your items
        </HashLink>
      </div>
    </>
  );
};

export default Menu;
