import React from "react";
import FilterCategory from "../Home/FeaturedProducts/FilterCategory/FilterCategory";
// import CardComponent from "../Home/FeaturedProducts/CardComponent/CardComponent";
import { BiSolidError } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const CategoryProducts = ({ data }) => {
  const navigate = useNavigate();
  const [adultModal, setAdultModal] = useState(data?.value?.toLowerCase());
  return (
    <div className="text-center mt-[0] md:mt-[120px] md:px-4 xl:px-0 w-full">
      <h2 className="uppercase text-[#1717178C] text-2xl md:text-4xl font-medium">
        <span className="text-black">{data?.name}</span>
      </h2>
      <p className="text-[#545454CC] mt-[20px] px-4 md:px-10">{data?.description}</p>

      <FilterCategory data={data} />
      {/* <CardComponent data={data?.data} /> */}

      {adultModal === "vapes" && (
        <div
          // onClick={handleOverlayClick}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 modal-overlay "
        >
          {/* Modal Content */}
          <div className="bg-white rounded-lg relative p-[20px] m-[20px] flex flex-col justify-center items-center">
            {/* Modal Content */}
            <BiSolidError
              style={{ color: "#DB4444" }}
              className="h-[50px] w-[50px]"
            />
            <h3 className="text-[22px] font-bold">
              Adult Product - Access restricted
            </h3>
            <p className=" mt-[5px] mb-[20px] text-[14px] text-center">
              Please confirm that you are over 18 or leave this website
            </p>
            <div className="w-[100%] flex justify-around items-center">
              <button
                onClick={() => setAdultModal(null)}
                className="bg-[green] text-white px-[15px] py-[8px] rounded mt-[15px] block"
              >
                Yes I am
              </button>
              <button
                onClick={() => navigate("/")}
                className="bg-[#ce2929] text-white px-[15px] py-[8px] rounded mt-[15px] block"
              >
                Leave This
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryProducts;
