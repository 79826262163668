import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import cartReducer from "../state/index";
import { productApi } from "../services/productApi";
import { userApi } from "../services/userApi";
import { orderApi } from "../services/orderApi";
import { categoryApi } from "../services/categoryApi";
import { featuredApi } from "../services/featuredApi";
import { couponApi } from "../services/couponApi";
export const store = configureStore({
  reducer: {
    cart: cartReducer,
    [productApi.reducerPath]: productApi.reducer,
    [userApi.reducerPath]: productApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [featuredApi.reducerPath]: featuredApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(productApi.middleware)
      .concat(userApi.middleware)
      .concat(orderApi.middleware)
      .concat(categoryApi.middleware)
      .concat(featuredApi.middleware)
      .concat(couponApi.middleware),
});
setupListeners(store.dispatch);
