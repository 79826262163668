import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { useState } from "react";
import "react-phone-input-2/lib/style.css";

const Checkout = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentDetails = JSON.parse(localStorage.getItem("price"));
  const [message, setMessage] = useState("");

  // todo : user will come from server
  // const [userLoggedIn, setUserLoggedIn] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const shipping = JSON.parse(localStorage.getItem("shippingDetails"));

      // setIsLoading(true);
      const name = `${shipping?.firstName} ${shipping?.lastName}`;
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/success`,
          shipping: {
            name: name,
            address: {
              line1: shipping?.streetAddress,
              city: shipping?.city,
              postal_code: shipping?.postSCode,
              state: shipping?.state,
              country: shipping?.country,
            },
            phone: shipping?.Sphone,
          },
        },
      });
      console.log(error);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } catch (error) {}
    // setIsLoading(false);
  };


  return (
    <form
      id="payment-form"
      className=" flex justify-center items-center mx-[20px] md:mx-[150px]"
      onSubmit={handleSubmit}
    >
      <div>
        <div className="bg-[#f7f7f7] w-[100%] md:w-[400px] lg:w-[450px] p-[15px] rounded-[5px] ">
          <div>
            <div className="flex flex-row justify-between items-center">
              <p className="text-[16px] font-medium mb-2">Sub-Total</p>
              <p className="text-[22px] font-medium mb-2">
                £{parseFloat(paymentDetails?.totalPrice).toFixed(2)}
              </p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p className="text-[16px] font-medium mb-2">Shipping</p>
              <p className="text-[22px] font-medium mb-2">
                £{parseFloat(paymentDetails?.shippingCharge).toFixed(2)}
              </p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p className="text-[16px] font-medium mb-2">Total</p>
              <p
                className={`text-[22px] font-medium mb-2 ${
                  paymentDetails?.couponDetails?.couponCode
                    ? "line-through"
                    : ""
                }`}
              >
                £
                {parseFloat(
                  paymentDetails?.totalPrice + paymentDetails?.shippingCharge
                ).toFixed(2)}
              </p>
            </div>
            {paymentDetails?.couponDetails?.couponCode && (
              <div className="flex flex-row justify-between items-center">
                <p className="text-[16px] font-medium mb-2">
                  Total with Coupon({paymentDetails?.couponDetails?.discount}%
                  off)
                </p>
                <p className="text-[22px] font-medium mb-2">
                  £
                  {parseFloat(
                    parseFloat(
                      paymentDetails?.totalPrice +
                        paymentDetails?.shippingCharge
                    ).toFixed(2) -
                      parseFloat(
                        paymentDetails?.totalPrice +
                          paymentDetails?.shippingCharge
                      ).toFixed(2) *
                        (paymentDetails?.couponDetails?.discount / 100).toFixed(
                          2
                        )
                  ).toFixed(2)}
                </p>
              </div>
            )}
            <div className="border-t my-4 border-[#A0A0A0]"></div>
          </div>
          <PaymentElement id="payment-element" />
          {message && (
            <p className="text-red-500 text-[13px] my-[10px]">{message}</p>
          )}
          <button
            className="w-[100%] rounded-3xl  bg-[#DF5645] p-[8px] font-semibold text-[white] tracking-wider mt-[15px]"
            disabled={!stripe}
          >
            Confirm
          </button>
        </div>
      </div>
    </form>
  );
};

export default Checkout;
