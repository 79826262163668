import React, { useContext } from "react";
import signupImage from "../../assets/Images/signup-image.png";
import GoogleIcon from "../../assets/Icons/icon-google.png";
import { useState } from "react";
import { useAddUserMutation } from "../../services/userApi";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

const Signup = ({ toggleLoginSignup, setIsOpenProfile }) => {
  const { setUser } = useContext(UserContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [addUser] = useAddUserMutation();
  const navigate = useNavigate();
  const handleSignUp = (e) => {
    e.preventDefault();
    const data = {
      email,
      name,
      password,
    };
    if (name && email && password) {
      addUser(data).then((res) => {
        if (res?.data?.status) {
          localStorage.setItem("token", res?.data?.token);
          setUser(res?.data?.data);
          navigate("/");
          setIsOpenProfile(false);
        } else {
          setError(res?.data.message);
          setIsOpenProfile(false);
        }
      });
    } else {
      setError("Please fill all the fields!");
      setIsOpenProfile(false);
    }
  };
  return (
    <div className="flex gap-[100px] 2xl:gap-[170px] w-full h-full px-[50px] 2xl:px-[100px] py-8">
      {/* Left Side (Image) */}
      <div className="w-1/2 hidden md:flex justify-center items-center">
        <img
          src={signupImage}
          alt="signupImage"
          className=" h-[400px] w-[400px] 2xl:h-[500px] 2xl:w-[500px] "
        />
      </div>

      {/* Right Side (Signup Form) */}
      <div className="w-full md:w-1/2 flex flex-col justify-center text-start ">
        <h2 className="text-[36px] font-medium mb-[10px] 2xl:mb-[24px]">
          Create an Account
        </h2>
        <p className="text-[16px] text-gray-500 mb-[20px] 2xl:mb-[48px]">
          Enter your details below:
        </p>

        {/* Signup Form */}
        <form
          onSubmit={handleSignUp}
          className="flex flex-col  gap-[25px] 2xl:gap-[25px]"
        >
          <div>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full border-b-2 border-gray-300 bg-transparent focus:outline-none"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              id="emailPhone"
              name="emailPhone"
              className="w-full border-b-2 border-gray-300 bg-transparent focus:outline-none"
              placeholder="Email or Phone Number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full border-b-2 border-gray-300 bg-transparent focus:outline-none"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <p className="text-[13px] text-[red] font-semibold text-center">
              {error}
            </p>
          )}
          <div className="flex flex-col">
            <button
              className="bg-[#DB4444] hover:bg-[#f74848] text-white font-medium md:font-bold py-[10px] 2xl:py-[16px] px-4 rounded focus:outline-none focus:shadow-outline w-full "
              type="submit"
            >
              Sign Up
            </button>

            {/* <div className="text-center mt-3">
              <button className="bg-white hover:bg-gray-200 text-gray-700 py-[10px] 2xl:py-[16px] px-4 rounded w-full focus:outline-none focus:shadow-outline mr-4 flex flex-row justify-center items-center gap-[16px] border ">
                <img src={GoogleIcon} alt="" /> <p>Sign Up with Google</p>
              </button>
            </div> */}
            <p className="text-gray-600 mt-4 text-center">
              Already have an account?
              <span
                onClick={toggleLoginSignup}
                className="text-[#DB4444] hover:underline ml-1 cursor-pointer"
              >
                Log in
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
