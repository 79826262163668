import React from "react";

const ContactUs = () => {
  return (
    <div  className="my-6 mt-[100px]"  id="contactus">
      <h2 className="uppercase text-[#1717178C] text-2xl md:text-4xl font-medium text-center mb-[28px]">
        Contact <span className="text-black">Us</span>
      </h2>

      <div className="max-w-[1440px]  mx-[auto] px-[15px] ">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8">
          <div className="w-full lg:w-1/2 border-[1px] border-[#CDCDCD] bg-[#F4F4F4] flex flex-col justify-center items-center gap-[8px] h-[200px] rounded-[8px]">
            <img src="/call.svg" alt="call" className="w-[40px] h-[40px]" />
            <h3 className="text-[18px] text-[#171717] font-bold">Phone</h3>
            <a
              href="tel:+447914779797"
              className="text-[#2691b8] text-[14px] hover:underline"
            >
              +44 7914 779797
            </a>
          </div>
          <div className="w-full lg:w-1/2 border-[1px] border-[#CDCDCD] bg-[#F4F4F4] flex flex-col justify-center items-center gap-[8px] h-[200px] rounded-[8px]">
            <img src="/mail.svg" alt="call" className="w-[40px] h-[40px]" />
            <h3 className="text-[18px] text-[#171717] font-bold">Email</h3>
            <a
              href="mailto:info@emporiumcentral.co.uk"
              className="text-[#2691b8] text-[14px] hover:underline"
            >
              info@emporiumcentral.co.uk
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
