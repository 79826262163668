// ApplicationForm.js
import React from "react";
import ProfileForm from "./ProfileForm";

const ApplicationForm = () => {
  return (
    <div
      className="pt-16 md:pt-[20px] lg:pt-[25px] pb-16 md:pb-[20px] lg:pb-[25px] bg-gray-200 mt-16 md:mt-24 lg:mt-32"
      id="buyitems"
    >
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <h1 className="text-gray-500 text-2xl md:text-3xl lg:text-4xl text-center">
          Have an item to{" "}
          <span className="text-black mb-3 md:mb-4 lg:mb-5">sell?</span>
        </h1>
        <p className="text-gray-500 text-center text-[14px] mb-[25px] mt-[16px]">
          Fill in the form to sell us your old items for cash!
        </p>

        <ProfileForm />
      </div>
    </div>
  );
};

export default ApplicationForm;
