import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../services/api";
import About from "../About/About";
import ContactUs from "../ContactUs/ContactUs";
import Reviews from "../Reviews/Reviews";
import ApplicationForm from "./ApplicationForm/ApplicationForm";
import Banner from "./Banner/Banner";
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
// import Announcement from "./Announcement/Announcement";

const Home = () => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}/reviews`).then((res) => {
      if (res.data?.status) {
        setReviews(res.data?.data?.feedbacks);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    script.defer = true;
    script.setAttribute("data-use-service-core", "");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="">
      {/* <Announcement /> */}
      <Banner />
      <FeaturedProducts />
      {/* <About /> */}
      <ContactUs />
      {reviews?.length > 0 && <Reviews data={reviews}></Reviews>}

      <ApplicationForm />
      {/* <Newsletter /> */}
      {/* <div class="elfsight-app-b96e8037-b0f9-4db3-9c23-ed026dd0fd66" data-elfsight-app-lazy></div>
       */}
    </div>
  );
};

export default Home;

// https://www.ebay.com/fdbk/feedback_profile/Emporium-central
// https://www.depop.com/emporium_central/ (click on feedback)
// Get all reviews from these two and display them as a carousel on homepage below have an item to sell?
