export const googleReview = [
  {
    type: "google",
    buyerName: "Md Shafiqul Islam",
    reviews: 1,
    rating: 5,
    leftBefore: "a week ago",
    comment: "Excellent customer service and super fast delivery, thank you",
    response: {
      comment: "Thank you so much",
      leftBefore: "a week ago",
    },
    likes: 1,
  },
  {
    type: "google",
    buyerName: "Shelley Windsor",
    reviews: 33,
    rating: 5,
    leftBefore: "8 months ago",
    comment: "Just wanted to say a really big thank you to Emporium Central. Brought some used and new toys from them, the toys were in amazing condition. was a really pleasant experience ordering from them my first order. The customer service was excellent.",
    likes: 2,
  },
  {
    type: "google",
    buyerName: "Shereen Zakaria",
    reviews: 4,
    rating: 5,
    leftBefore: "a month ago",
    comment: "Amazing customer service, great selection of products and super fast delivery",
    response: {
      comment: "Thank you for your kind words",
      leftBefore: "a week ago",
    },
    likes: 1,
  },
  {
    type: "google",
    buyerName: "Rishil Patel",
    reviews: 4,
    rating: 5,
    leftBefore: "8 months ago",
    comment: "I recently came across this amazing online retailer called Emporium Central, just thought I had to share this! They take your items for cash. It’s a no brainer right? This is definitely a one stop shop catered for everyone.",
    likes: 2,
  },
  {
    type: "google",
    buyerName: "Torichops Richards",
    reviews: 5,
    rating: 5,
    leftBefore: "8 months ago",
    comment: "So impressed with the quality and service here, the smoothest of transactions and when I had a query I was dealt with professionally and efficiently! Will most definitely be shopping here again and very happy to recommend. Thank you!",
    likes: 2,
  },
  {
    type: "google",
    buyerName: "Dale Lewis",
    reviews: 6,
    rating: 5,
    leftBefore: "7 months ago",
    comment: "Brilliant service awesome guy great deals and delivery is fantastic never had an issue with anything would highly recommend",
    likes: 2,
  },
  {
    type: "google",
    buyerName: "Juber Ahmed",
    reviews: 54,
    rating: 5,
    leftBefore: "6 months ago",
    comment: "Great prices, and some cool stuff",
    likes: 1,
  },
  {
    type: "google",
    buyerName: "spyderxyz",
    reviews: 1,
    rating: 5,
    leftBefore: "8 months ago",
    comment: "Fast delivery, went above and beyond with customer service.",
  },
];
