import React, { useState } from "react";
import FilterCategory from "./FilterCategory/FilterCategory";
import {
  useGetNewProductsQuery,
  useProductsQuery,
} from "../../../services/productApi";
import { useSelector } from "react-redux";
import { useFeaturedsQuery } from "../../../services/featuredApi";

const FeaturedProducts = () => {
  const [page, setPage] = useState(1);
  const cat = useSelector((state) => state.cart.category);
  const [value, setValue] = useState("featured");
  const { data, isLoading: isHomeLoading } = useProductsQuery({
    cat: cat,
    page: page,
    limit: 8,
  });
  const { data: newProducts, isLoading: newProductLoading } =
    useGetNewProductsQuery();
  const { data: featuredProduct, isLoading: featuredLoading } =
    useFeaturedsQuery();
  console.log(featuredProduct);
  return (
    <div className="text-center mt-5 md:px-4 xl:px-0 w-full">
      <h2 className="uppercase text-[#1717178C] text-2xl md:text-4xl font-medium">
        Our Featured <span className="text-black">Products</span>
      </h2>

      <FilterCategory
        from={"featured"}
        homeData={data?.data}
        isHomeLoading={isHomeLoading}
        newProductLoading={newProductLoading}
        newProducts={newProducts?.data}
        featuredProduct={featuredProduct?.data}
        featuredLoading={featuredLoading}
        value={value}
        setValue={setValue}
      />
      {value === "all" && (
        <div className="max-w-[1440px] mt-[20px] flex flex-col-reverse lg:flex-row justify-between items-center lg:justify-between gap-[20px] mx-auto lg:w-[96%]">
          <div>
            Page {page} of {data?.pagination?.totalPages}
          </div>
          <div>Total {data?.pagination?.totalItems} Items</div>
          <div className="flex items-center">
            {page > 1 ? (
              <button
                onClick={() => setPage(page - 1)}
                className="text-[#DF5645] border-[1px] border-[#DF5645] px-[15px] py-[6px] w-[80px] rounded font-bold mr-[10px] hover:bg-[#DF5645]  hover:text-white flex justify-center items-center"
              >
                Previous
              </button>
            ) : (
              <button
                disabled
                className="text-[grey] border-[1px] border-[grey] px-[15px] py-[6px] w-[80px] rounded font-bold mr-[10px] flex justify-center items-center"
              >
                Previous
              </button>
            )}
            {page < data?.pagination?.totalPages ? (
              <button
                onClick={() => setPage(page + 1)}
                className="text-[#DF5645] border-[1px] border-[#DF5645] px-[15px] py-[6px] w-[80px] rounded font-bold ml-[10px] hover:bg-[#DF5645]  hover:text-white flex justify-center items-center"
              >
                Next
              </button>
            ) : (
              <button
                disabled
                className="text-[grey] border-[1px] border-[grey] px-[15px] py-[6px] w-[80px] rounded font-bold ml-[10px] flex justify-center items-center"
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedProducts;
