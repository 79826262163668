import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartOpen: false,
  cart: [],
  items: [],
  category: "all",
  wishList: [],
  valueSub: "all",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.cart = action.payload;
    },

    addToCart: (state, action) => {
      state.cart = [...state.cart, action.payload.item];
    },
    updateCategory: (state, action) => {
      state.category = action.payload;
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item._id !== action.payload._id);
    },

    setWishList: (state, action) => {
      state.wishList = action.payload;
    },

    addToWishList: (state, action) => {
      state.wishList = [...state.wishList, action.payload.item];
    },
    removeFromWishList: (state, action) => {
      state.wishList = state.wishList.filter(
        (item) => item._id !== action.payload._id
      );
    },
    changeValueSub: (state, action) => {
      state.valueSub = action.payload;
    },
    increaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item._id === action.payload._id) {
          item.count++;
        }
        return item;
      });
    },
    decreaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item._id === action.payload._id && item.count > 1) {
          item.count--;
        }
        return item;
      });
    },
    increaseClothesCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item._id === action.payload._id) {
          item.counter.quantity++;
        }
        return item;
      });
    },
    increaseClothesCountCart: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item._id === action.payload._id) {
          item?.cquantity.forEach((element) => {
            if (element?.name === action.payload.counter.name) {
              if (element.quantity > action.payload.counter.quantity) {
                item.counter.quantity++;
              }
            }
          });
        }
        return item;
      });
    },
    decreaseClothesCount: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item._id === action.payload._id && item.counter?.quantity > 1) {
          item.counter.quantity--;
        }
        return item;
      });
    },
    setIsCartOpen: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
  },
});

export const {
  setItems,
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  setIsCartOpen,
  updateCategory,
  setWishList,
  removeFromWishList,
  addToWishList,
  increaseClothesCount,
  decreaseClothesCount,
  increaseClothesCountCart,
  changeValueSub,
} = cartSlice.actions;

export default cartSlice.reducer;
