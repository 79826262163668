import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/products`,
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    products: builder.query({
      query: (data) =>
        `/?cat=${data?.cat}&page=${data?.page}&limit=${data?.limit}`,
      providesTags: ["Product"],
    }),
    getCatProducts: builder.query({
      query: (data) =>
        `/getCatProducts/?cat=${data?.cat}&subCat=${data?.subCat}&page=${data?.page}&limit=${data?.limit}`,
      providesTags: ["Product"],
    }),
    getNewProducts: builder.query({
      query: () => `/getNewProduct`,
      providesTags: ["Product"],
    }),
    getProductById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Product"],
    }),
    addProduct: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Product"],
    }),
    makeInquery: builder.mutation({
      query: (data) => ({
        url: "/send-email",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Product"],
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Product"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});
export const {
  useProductsQuery,
  useGetProductByIdQuery,
  useGetCatProductsQuery,
  useMakeInqueryMutation,
  useGetNewProductsQuery
} = productApi;
