import React, { useContext, useEffect, useState } from "react";
import { FaRegHeart, FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../App";
import iconLogout from "../../../../assets/Icons/svg/Icon-logout.svg";
import iconCart from "../../../../assets/Icons/svg/icon-cart.svg";
import iconOrder from "../../../../assets/Icons/svg/icon-order.svg";
import { setItems, setWishList } from "../../../../state";
import Login from "../../../Login/Login";
import Signup from "../../../SignUp/Signup";
import Calculations from "./Calculations/Calculations";
import Items from "./Items/Items";

const RightIcons = ({
  isCategoryMenuOpen,
  setIsCategoryMenuOpen,
  isOpenProfile,
  setIsOpenProfile,
  isOpenCart,
  setIsOpenCart,
  isRegisterOpen,
  setIsRegisterOpen,
  isOpenLoginModal,
  setIsOpenLoginModal,
  setIsNavOpen,
  handleCloseEverything,
  wishListDrop,
  setWishListDrop,
}) => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const [totalPrice, setTotalPrice] = useState(0);
  const wishList = useSelector((state) => state.cart.wishList);
  const checkLocalCart = localStorage.getItem("cart");
  const checkLocalWish = localStorage.getItem("wishlist");

  useEffect(() => {
    if (checkLocalWish && JSON.parse(checkLocalWish)?.length > 0) {
      dispatch(setWishList(JSON.parse(checkLocalWish)));
    }
  }, [checkLocalWish, dispatch]);

  useEffect(() => {
    if (checkLocalCart && JSON.parse(checkLocalCart)?.length > 0) {
      dispatch(setItems(JSON.parse(checkLocalCart)));
    }
  }, [checkLocalCart, dispatch]);

  useEffect(() => {
    const total = cart?.reduce((total, item) => {
      if (item?.category === "fashion" && item?.subCategory === "clothes") {
        total = total + item?.counter?.quantity * item?.price;
      } else {
        total = total + item?.count * item?.price;
      }
      return total;
    }, 0);
    setTotalPrice(total);
  }, [cart]);

  const toggleProfileDropdown = () => {
    setIsOpenProfile(!isOpenProfile);
    setIsOpenCart(false);
    setIsOpenCart(false);
    setIsCategoryMenuOpen(false);
    setWishListDrop(false);
  };
  const toggleCartDropdown = () => {
    setIsOpenCart(!isOpenCart);
    setIsOpenProfile(false);
    setIsCategoryMenuOpen(false);
    setWishListDrop(false);
  };

  // modal function

  const handleLoginModal = () => {
    setIsOpenLoginModal(true);
    setIsCategoryMenuOpen(false);
    setIsOpenProfile(false);
    setIsOpenCart(false);
    // setIsOpenLogin(false);
    setIsOpenCart(false);
  };

  const closeModal = () => {
    handleCloseEverything();
    // setIsOpenLoginModal(false);
    // setIsOpenLogin(false);
    // setIsNavOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeModal();
      handleCloseEverything();
    }
  };

  // toggole login page and sign up
  const toggleLoginSignup = () => {
    setIsRegisterOpen(!isRegisterOpen);
    // setIsRegisterOpen(false)
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/");
  };
  const menuList = [
    {
      id: 1,
      title: "My Order",
      path: "/my-orders",
      icon: iconOrder,
    },
    // {
    //   id: 2,
    //   title: "My Cancellations",
    //   path: "/",
    //   icon: iconReviews,
    // },
    // {
    //   id: 3,
    //   title: "My Reviews",
    //   path: "/",
    //   icon: iconCancle,
    // },
    // {
    //   id: 4,
    //   title: "Logout",
    //   path: "/",
    //   icon: iconLogout,
    // },
  ];
  return (
    <div className="flex flex-row  items-center w-max gap-[30px] 2xl:gap-[35px] mt-2 lg:mt-0">
      {/* Profile icon  */}

      {user && (
        <div className="relative inline-block text-left">
          <FaRegUserCircle
            className="text-[#DF5645] h-[24px] w-[24px] absolute top-[-10px] right-[-10px] cursor-pointer"
            onClick={toggleProfileDropdown}
          />

          {user && isOpenProfile && (
            <div
              className="origin-top-right absolute md:right-0 mt-2 w-56 rounded-2xl shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[14px]"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="py-1" role="none">
                {menuList.map((menuItem, index) => (
                  <Link
                    key={menuItem.id}
                    to={menuItem?.path}
                    className="flex flex-row items-center gap-[16px] px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${index + 1}`}
                  >
                    <img src={menuItem.icon} alt="" /> {menuItem.title}
                  </Link>
                ))}
                <span
                  className="flex flex-row items-center gap-[16px] px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                  role="menuitem"
                  tabIndex="-1"
                  onClick={handleLogout}
                >
                  <img src={iconLogout} alt="" /> Sign Out
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* cart icon  */}
      <div className="hidden lg:block md:relative text-left">
        <div className="relative">
          {cart?.length > 0 ? (
            <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
              {cart?.length}
            </p>
          ) : (
            <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
              0
            </p>
          )}

          <img
            type="button"
            className=" group rounded-md cursor-pointer"
            onClick={toggleCartDropdown}
            src={iconCart}
            alt=""
          />
        </div>

        {isOpenCart && (
          <div
            className={`md:origin-top-right absolute right-0 md:right-0  mt-2 rounded-2xl shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[38px] w-screen lg:w-[482px] ${
              cart?.length > 0 ? "h-[510px]  lg:h-[520px] " : "h-[200px]"
            } flex flex-col justify-between text-[#282828]`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            {cart?.length > 0 ? (
              <>
                {" "}
                <div className="h-[400px] overflow-y-auto pr-6">
                  {cart?.map((c) => (
                    <Items from={"cart"} c={c} />
                  ))}
                </div>
                <Calculations
                  totalPrice={totalPrice}
                  handleCloseEverything={handleCloseEverything}
                />
              </>
            ) : (
              <div className="h-[100%] flex justify-center items-center">
                <h3>No Products Added</h3>
              </div>
            )}
          </div>
        )}
      </div>

      {/* wishlist */}
      <div className="hidden lg:block md:relative text-left">
        <div className="relative">
          {wishList?.length > 0 ? (
            <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
              {wishList?.length}
            </p>
          ) : (
            <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
              0
            </p>
          )}
          <FaRegHeart
            onClick={() => {
              setWishListDrop(!wishListDrop);
              setIsOpenCart(false);
              setIsOpenProfile(false);
              setIsCategoryMenuOpen(false);
            }}
            className="h-[24px] w-[24px] mt-[3px] cursor-pointer"
          />
        </div>

        {wishListDrop && (
          <div
            className={`md:origin-top-right absolute right-0 md:right-0  mt-2 rounded-2xl shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[38px] w-screen lg:w-[482px] ${
              wishList?.length > 0
                ? "h-[510px]  lg:h-[520px] 2xl:h-[551px]"
                : "h-[200px]"
            } flex flex-col justify-between text-[#282828]`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            {wishList?.length > 0 ? (
              <>
                {" "}
                <div className="h-[400px] overflow-y-auto pr-6">
                  {wishList?.map((c) => (
                    <Items
                      setWishListDrop={setWishListDrop}
                      from={"wishlist"}
                      c={c}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="h-[100%] flex justify-center items-center">
                <h3>No Products Added</h3>
              </div>
            )}
          </div>
        )}
      </div>
      {/* menu icon  */}
      {/* to do  */}
      {!user && (
        <button
          className="bg-[#DF5645]  py-[10px] px-[25px] text-white text-[16px] tracking-wide rounded-md "
          onClick={handleLoginModal}
        >
          Login
        </button>
      )}

      {/* Modal Overlay */}
      {!user && isOpenLoginModal && (
        <div
          onClick={handleOverlayClick}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 modal-overlay"
        >
          {/* Modal Content */}
          <div className="bg-white rounded-lg relative md:w-5/6 h-max 2xl:w-[1274px]  ">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-4 text-gray-600 hover:text-gray-800 text-[20px]"
            >
              &times;
            </button>

            {/* Modal Content */}
            {!isRegisterOpen ? (
              <Login
                setIsOpenProfile={setIsOpenProfile}
                toggleLoginSignup={toggleLoginSignup}
              />
            ) : (
              <Signup
                setIsOpenProfile={setIsOpenProfile}
                toggleLoginSignup={toggleLoginSignup}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightIcons;
