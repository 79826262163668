import React, { useState } from "react";
import applicationImage from "../../../assets/Images/applicationForm.png";
import { useMakeInqueryMutation } from "../../../services/productApi";
import { ToastContainer, toast } from "react-toastify";
import { Circles } from "react-loader-spinner";

const ProfileForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [inquery, setInquery] = useState("");
  const [items, setItems] = useState("");
  const [makeInquery] = useMakeInqueryMutation();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      name,
      email,
      inquery,
      items,
    };
    makeInquery(data).then((res) => {
      if (res?.data?.status) {
        toast.success(
          "We have received your Inquery. We will contact you soon!"
        );
        setLoading(false);
        setName("");
        setEmail("");
        setInquery("");
        setItems("");
      } else {
        setLoading(false);
        toast.error("Failed to send the email!");
      }
    });
  };
  return (
    <div className="flex flex-col md:flex-row-reverse items-center md:items-start">
      {/* Left Side with Photo */}
      {/* <div className="w-full md:w-1/2 md:order-2 p-4">
        <img
          src={applicationImage}
          alt="ProfilePhoto"
          className="h-auto md:h-[521px] md:w-[531px] object-cover mx-auto"
        />
      </div> */}

      {/* Right Side with Form */}
      <div className="w-full">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-3 w-full max-w-[1440px] mx-auto"
        >
          <h3 className="font-semibold text-black text-lg md:text-xl lg:text-1xl mb-[10px]">
            Fill the Application
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
            {/* Name */}
            <div className="mb-[10px]">
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="w-full border-2 border-gray-400 bg-transparent focus:outline-none px-[15px] py-[7px] rounded"
                placeholder="Full Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Email */}
            <div className="mb-[10px]">
              <input
                type="email"
                id="email"
                name="email"
                className="w-full border-2 border-gray-400 bg-transparent focus:outline-none px-[15px] py-[7px] rounded"
                placeholder="Enter Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-[10px]">
            <input
              type="text"
              id="items"
              name="items"
              className="w-full border-2 border-gray-400 bg-transparent focus:outline-none px-[15px] py-[7px] rounded"
              placeholder="Enter Item(s)"
              required
              value={items}
              onChange={(e) => setItems(e.target.value)}
            />
          </div>

          <div className="mb-[10px]">
            <textarea
              type="text"
              id="inquery"
              name="inquery"
              className="w-full h-[150px] border-2 border-gray-400 bg-transparent focus:outline-none px-[15px] py-[7px] rounded"
              placeholder="Description"
              required
              value={inquery}
              onChange={(e) => setInquery(e.target.value)}
            />
          </div>

          {/* Submit Button */}
          <div className="text-center">
            {loading ? (
              <button
                disabled
                className="bg-gray-500 text-white px-4 py-2 rounded focus:outline-none w-[100%] flex justify-center items-center"
              >
                <Circles
                  height="25"
                  width="25"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </button>
            ) : (
              <button
                type="submit"
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none w-[100%]"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
