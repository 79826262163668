import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import iconCart from "../../../assets/Icons/svg/icon-cart.svg";
import iconThreeLine from "../../../assets/Icons/svg/icon-threeline.svg";
import logo from "../../../assets/logo.svg";
import Announcement from "../../Home/Announcement/Announcement";
import Calculations from "./NavComponent/Calculations/Calculations";
import Items from "./NavComponent/Items/Items";
import Menu from "./NavComponent/Menu";
import RightIcons from "./NavComponent/RightIcons";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  // todo : user will come from server
  // const [userLoggedIn, setUserLoggedIn] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  const [wishListDrop, setWishListDrop] = useState(false);
  const wishList = useSelector((state) => state.cart.wishList);

  const toggleCartDropdown = () => {
    setIsOpenCart(!isOpenCart);
    setIsOpenProfile(false);
    setIsCategoryMenuOpen(false);
  };
  const handleCloseEverything = () => {
    setIsCategoryMenuOpen(false);
    setIsOpenProfile(false);
    setIsOpenCart(false);
    setIsRegisterOpen(false);
    setIsOpenLoginModal(false);
    setIsNavOpen(false);
  };

  const menuProps = {
    isCategoryMenuOpen,
    setIsCategoryMenuOpen,
    isOpenProfile,
    setIsOpenProfile,
    isOpenCart,
    setIsOpenCart,
    isRegisterOpen,
    setIsRegisterOpen,
    isOpenLoginModal,
    setIsOpenLoginModal,
    setIsNavOpen,
    handleCloseEverything,
    wishListDrop,
    setWishListDrop,
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("nav-overlay")) {
      handleCloseEverything();
    }
  };

  useEffect(() => {
    const total = cart?.reduce((total, item) => {
      if (item?.category === "fashion" && item?.subCategory === "clothes") {
        total = total + item?.counter?.quantity * item?.price;
      } else {
        total = total + item?.count * item?.price;
      }
      return total;
    }, 0);
    setTotalPrice(total);
  }, [cart]);

  return (
    <div>
      <div className="flex justify-between items-center  sticky top-0 left-0 right-0 bg-white px-[2%] lg:px-[5%] z-[1000] shadow-sm ">
        <Link to="/">
          <img
            className="h-[40px] w-[110px] md:h-[80px] md:w-[214px] block lg:hidden"
            src={logo}
            alt=""
          />
        </Link>
        {/* desktop view  */}
        <div className=" hidden md:flex justify-between items-center w-full">
          <Link to="/">
            <img
              className="h-[40px] w-[110px] md:h-[80px] md:w-[214px] "
              src={logo}
              alt=""
            />
          </Link>
          <Menu closeNav={closeNav} {...menuProps} />
          <RightIcons {...menuProps} />
        </div>

        {/* mobile view  */}
        <div className="flex justify-end gap-[20px] items-center md:hidden  h-[60px]">
          <div className="block lg:hidden md:relative text-left">
            <div className="relative">
              {cart?.length > 0 ? (
                <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
                  {cart?.length}
                </p>
              ) : (
                <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
                  0
                </p>
              )}

              <img
                type="button"
                className=" group rounded-md cursor-pointer"
                onClick={toggleCartDropdown}
                src={iconCart}
                alt=""
              />
            </div>

            {isOpenCart && (
              <div
                className={`md:origin-top-right absolute left-[10px] right-[10px] md:right-0  mt-2 rounded-2xl shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[38px] lg:w-[482px] ${
                  cart?.length > 0
                    ? "h-[510px]  lg:h-[520px] 2xl:h-[751px]"
                    : "h-[200px]"
                } flex flex-col justify-between text-[#282828]`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                {cart?.length > 0 ? (
                  <>
                    {" "}
                    <div className="h-[400px] overflow-y-auto pr-6">
                      {cart?.map((c) => (
                        <Items from={"cart"} c={c} />
                      ))}
                    </div>
                    <Calculations
                      totalPrice={totalPrice}
                      handleCloseEverything={handleCloseEverything}
                    />
                  </>
                ) : (
                  <div className="h-[100%] flex justify-center items-center">
                    <h3>No Products Added</h3>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="block lg:hidden md:relative text-left">
            <div className="relative">
              {wishList?.length > 0 ? (
                <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
                  {wishList?.length}
                </p>
              ) : (
                <p className="absolute top-[-15px] right-[-10px] text-white bg-red-500 rounded-full w-[20px] h-[20px] flex justify-center items-center text-[12px]">
                  0
                </p>
              )}
              <FaRegHeart
                onClick={() => {
                  setWishListDrop(!wishListDrop);
                  setIsOpenCart(false);
                  setIsOpenProfile(false);
                  setIsCategoryMenuOpen(false);
                }}
                className="h-[24px] w-[24px] mt-[3px] cursor-pointer"
              />
            </div>

            {wishListDrop && (
              <div
                className={`md:origin-top-right absolute left-[10px] right-[10px] md:right-0  mt-2 rounded-2xl shadow-2xl  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 p-[38px] lg:w-[482px] ${
                  wishList?.length > 0
                    ? "h-[510px]  lg:h-[520px] 2xl:h-[551px]"
                    : "h-[200px]"
                } flex flex-col justify-between text-[#282828]`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                {wishList?.length > 0 ? (
                  <>
                    {" "}
                    <div className="h-[400px] overflow-y-auto pr-6">
                      {wishList?.map((c) => (
                        <Items
                          setWishListDrop={setWishListDrop}
                          from={"wishlist"}
                          c={c}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="h-[100%] flex justify-center items-center">
                    <h3>No Products Added</h3>
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            className="flex mr-3"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <img src={iconThreeLine} alt="" />
          </button>

          {/* mobile nav  */}
          {isNavOpen && (
            <div
              onClick={handleOverlayClick}
              class="fixed inset-0 flex items-start justify-end z-50 overflow-hidden bg-black bg-opacity-100 nav-overlay"
            >
              <div class="bg-white rounded-bl-md relative w-[100%] h-[100vh] overflow-auto py-4 px-4">
                <img
                  className="h-[40px] w-[110px] md:h-[70px] md:w-[214px] absolute top-4 left-0"
                  src={logo}
                  alt=""
                />
                <button
                  onClick={() => {
                    closeNav();
                    setIsCategoryMenuOpen(false);
                  }}
                  className="absolute top-5 right-4 text-[24px] text-gray-600 hover:text-gray-800"
                >
                  &times;
                </button>
                <Menu closeNav={closeNav} {...menuProps} />
                <RightIcons {...menuProps} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div >
        <Announcement />
      </div>
    </div>
  );
};

export default Navbar;
