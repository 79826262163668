import React from "react";

const AboutUs = () => {
  return (
    <div className="bg-[#F7F7F7]  py-6 sm:py-12 lg:py-20">
      <div className="max-w-[1440px]  mx-[auto] px-[15px]">
        <h3 className="text-sm sm:text-base text-gray-600 mb-6">
          Home / About
        </h3>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8">
          {/* title and description  */}
          <div className="w-full sm:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-semibold text-center">OUR STORY</h1>
            <p className="py-2 sm:py-4">
              Emporium Central is based within West Midlands, our company is
              renowned for maintaining exceptional standards. We take great
              pride in upholding these high levels of quality across all areas
              of our business, ensuring our customers receive only the best
              service possible. The team at Emporium Central maintains high
              standards and sources quality products for their customers.
            </p>
            <p className="py-2 sm:py-4">
              We source a wide range of products at great deals, including toys,
              retro consoles, video games, clothes, household items, and much
              more.
            </p>
            <p className="py-2 sm:py-4">
              We also offer a combination of brand new and used items at
              unbeatable prices. Additionally, we will buy your unwanted items
              for cash.
            </p>
            <p className="py-2 sm:py-4">
              Contact our customer service team for more information on this
              service.
            </p>
            <p className="py-2 sm:py-4">
              Our team is friendly and ready to assist you with any questions or
              concerns you may have.
            </p>
          </div>
          {/* image section  */}
          {/* <div className="w-full sm:w-1/2">
            <img
              className="w-full h-auto object-cover"
              src={"/about-us.svg"}
              alt="About Us"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
