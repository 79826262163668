import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useGetCategoryByNameQuery } from "../../services/categoryApi";
import ApplicationForm from "../Home/ApplicationForm/ApplicationForm";
import CategoryProducts from "./CategoryProducts";

const Category = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCategoryByNameQuery(id);
  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div>
      {data?.data?.banners?.length > 0 && (
        <img
          src={`${data?.data?.banners}`}
          alt="banner"
          className="w-[100%] mb-[30px] lg:mb-[40px] xl:mb-[60px]"
        />
      )}
      <CategoryProducts data={data?.data} />
      <ApplicationForm />
      {/* <Newsletter /> */}
    </div>
  );
};

export default Category;
