import React, { useContext, useState } from "react";
import loginImage from "../../assets/Images/login-image.png";
import GoogleIcon from "../../assets/Icons/icon-google.png";
import {
  useAddUserMutation,
  useLoginUserMutation,
} from "../../services/userApi";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

const Login = ({ toggleLoginSignup, setIsOpenProfile }) => {
  const { setUser } = useContext(UserContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginUser] = useLoginUserMutation();
  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    if (email && password) {
      loginUser(data).then((res) => {
        if (res?.data?.status) {
          localStorage.setItem("token", res?.data?.token);
          setUser(res?.data?.data);
          navigate("/");
          setIsOpenProfile(false)
        } else {
          setError(res?.data.message);
          setIsOpenProfile(false)
        }
      });
    } else {
      setError("Please fill all the fields!");
    }
  };

  return (
    <div className="flex gap-[100px] 2xl:gap-[170px] w-full h-max px-[50px] 2xl:px-[100px] py-8">
      {/* Left Side (Image) */}
      <div className="w-1/2 hidden md:flex justify-center items-center">
        <img
          src={loginImage}
          alt="loginImage"
          className="h-[400px] w-[400px] 2xl:h-[500px] 2xl:w-[500px]"
        />
      </div>

      {/* Right Side (Login Form) */}
      <div className="w-full md:w-1/2 flex flex-col justify-center text-start ">
        <h2 className="text-[28px] 2xl:text-[36px] font-medium mb-[23px] 2xl:mb-[24px]">
          Log in to Exclusive
        </h2>
        <p className="text-[16px] text-gray-500 mb-[24px] 2xl:mb-[48px]">
          Enter your details below:
        </p>

        {/* Login Form */}
        <form
          onSubmit={handleLogin}
          className="flex flex-col  gap-[25px] 2xl:gap-[35px]"
        >
          <div>
            <input
              type="text"
              id="emailPhone"
              name="emailPhone"
              className="w-full border-b-2 border-gray-300 bg-transparent focus:outline-none"
              placeholder="Email or Phone Number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full border-b-2 border-gray-300 bg-transparent focus:outline-none"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <p className="text-[13px] text-[red] font-medium md:font-semibold text-center">
              {error}
            </p>
          )}

          <div className="flex flex-row justify-between items-center gap-[16px]">
            <button
              className="bg-[#DB4444] hover:bg-[#f74848] text-white font-medium md:font-bold py-[8px] md:py-[16px] rounded focus:outline-none focus:shadow-outline w-max px-[20px] md:px-[48px] mb-4"
              type="submit"
            >
              Sign In
            </button>

            <a className="text-[#DB4444] hover:underline ml-3 cursor-pointer">
              Forget Password?
            </a>
          </div>
        </form>
        <p className="text-gray-600 mt-4 text-center">
          Don't have an account?
          <span
            onClick={toggleLoginSignup}
            className="text-[#DB4444] hover:underline ml-1 cursor-pointer"
          >
            Sign Up
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
