import React from "react";
import { useNavigate } from "react-router-dom";

// const cardData = [
//   {
//     id: 1,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: camera,
//     price: 49,
//   },
//   {
//     id: 2,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: facewash,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: book,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: doll,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: chair,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: frok,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: laptop,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: vegetables,
//     price: 49,
//   },
//   {
//     id: 3,
//     name: 'New Arrivals',
//     type: 'Essential oil',
//     image: shoes,
//     price: 49,
//   },
// ];

const CardComponent = ({ data, from }) => {
  const navigate = useNavigate();
  const getClothesQuantity = (cquantity) => {
    let total = 0;
    for (const i of cquantity) {
      total += i.quantity;
    }
    return total;
  };
  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-[15px] lg:gap-[20px] w-[90%] mx-auto lg:w-[96%]">
      {from === "featured" ? (
        <>
          {data?.map((card) => (
            <div
              onClick={() =>
                navigate(`/product-details/${card?.productId?._id}`)
              }
              className="mx-auto cursor-pointer w-[100%] rounded-sm"
              key={card?._id}
            >
              <div className="bg-white card-shadow h-[270px] lg:h-[430px] w-[100%] relative  rounded-sm">
                <div className="overflow-hidden">
                  <img
                    src={card?.productId?.images[0]}
                    alt={card?.productId?.name}
                    className="w-full h-[120px] lg:h-[310px] 2xl:h-[310px] object-contain"
                  />
                </div>
                <div className="p-3 text-sm lg:p-4 text-start ">
                  <h2 className="font-semibold text-gray-600 text-[14px] lg:text-[18px] ">
                    {card?.productId?.name?.length > 52
                      ? card?.productId?.name.slice(0, 52) + "..."
                      : card?.productId?.name}
                  </h2>
                  <p className="font-medium text-[12px] text-gray-600 lg:text-[15px] my-[7px] mb-[15px]">
                    {card?.productId?.brand}
                  </p>
                  {card?.productId?.category === "fashion" &&
                  card?.productId?.subCategory === "clothes" ? (
                    <>
                      {getClothesQuantity(card?.productId?.cquantity) === 0 ? (
                        <p className="font-semibold text-red-600 text-[14px] absolute bottom-[13px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="font-semibold text-gray-600 text-[16px] absolute bottom-[13px]">
                          £{card?.productId?.price}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {card?.productId?.quantity === 0 ? (
                        <p className="font-semibold text-red-600 text-[14px] absolute bottom-[13px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="font-semibold text-gray-600 text-[16px] absolute bottom-[13px]">
                          £{card?.productId?.price}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {data?.map((card) => (
            <div
              onClick={() => navigate(`/product-details/${card?._id}`)}
              className="mx-auto cursor-pointer w-[100%] rounded-sm"
              key={card?._id}
            >
              <div className="bg-white card-shadow h-[270px] lg:h-[430px] w-[100%] relative  rounded-sm">
                <div className="overflow-hidden">
                  <img
                    src={card?.images[0]}
                    alt={card?.name}
                    className="w-full h-[120px] lg:h-[310px] 2xl:h-[310px] object-contain"
                  />
                </div>
                <div className="p-3 text-sm lg:p-4 text-start ">
                  <h2 className="font-semibold text-gray-600 text-[14px] lg:text-[18px] ">
                    {card?.name?.length > 52
                      ? card?.name.slice(0, 52) + "..."
                      : card?.name}
                  </h2>
                  <p className="font-medium text-[12px] text-gray-600 lg:text-[15px] my-[7px] mb-[15px]">
                    {card?.brand}
                  </p>
                  {card?.category === "fashion" &&
                  card?.subCategory === "clothes" ? (
                    <>
                      {getClothesQuantity(card?.cquantity) === 0 ? (
                        <p className="font-semibold text-red-600 text-[14px] absolute bottom-[13px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="font-semibold text-gray-600 text-[16px] absolute bottom-[13px]">
                          £{card?.price}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {card?.quantity === 0 ? (
                        <p className="font-semibold text-red-600 text-[14px] absolute bottom-[13px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="font-semibold text-gray-600 text-[16px] absolute bottom-[13px]">
                          £{card?.price}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default CardComponent;
