import React from "react";

const Announcement = () => {
  return (
    <div className="h-[30px] bg-[#ffba24] w-full flex justify-center items-center text-center px-4">
      <p className="uppercase font-bold text-[14px] sm:text-[16px] md:text-xl whitespace-nowrap">
        FREE STANDARD DELIVERY ON ORDERS OVER £50
      </p>
    </div>
  );
};

export default Announcement;
