import React from "react";
import PaymentForm from "./PaymentForm";
import Newsletter from "../Home/Newsletter/Newsletter";
const PaymentPage = () => {
  return (
    <div className="bg-[#fcfbfb]">
      <div className="pb-[100px] pt-[50px]">
        <PaymentForm />
      </div>

      {/* <Newsletter /> */}
    </div>
  );
};

export default PaymentPage;
