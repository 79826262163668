import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../App";

const Calculations = ({ totalPrice, handleCloseEverything }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  console.log(user);
  return (
    <div className="mb-[20px]">
      {/* <div className="border-t my-4 border-[#A0A0A0]"></div> */}

      <div className="flex justify-between mb-4 text-[16px]">
        <p>Subtotal</p>
        <p>£{parseFloat(totalPrice).toFixed(2)}</p>
      </div>
      {/* <div className="flex justify-between mb-4 text-[16px]">
        <p>Delivery</p>
        <p>£3.95</p>
      </div> */}

      {/* Divider */}

      {/* Total and Purchase Button */}
      <div className="flex flex-row justify-between items-center">
        <p className="text-[16px] font-medium mb-2">Total</p>
        <p className="text-[22px] font-medium mb-2">
          £{parseFloat(totalPrice).toFixed(2)}
        </p>
      </div>

      <button
        onClick={() => {
          navigate("/payment");
          handleCloseEverything();
        }}
        className="w-full bg-[#DF5645] text-white py-2  hover:bg-[#c93c2a]"
      >
        Purchase
      </button>
    </div>
  );
};

export default Calculations;
