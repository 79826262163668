import React from "react";
import { useNavigate } from "react-router";
import { useProductsQuery } from "../../../services/productApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles


const ProductSlider = ({ cat }) => {
  const { data: products } = useProductsQuery({ cat: cat });


  const navigate = useNavigate();

  return (
    <div className="w-full  overflow-hidden suggested-product">
       <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
         {products?.data?.map((product, index) => (
          <SwiperSlide>
             <div
            onClick={() => navigate(`/product-details/${product?._id}`)}
            className="shadow-md rounded-md art-product-card lg:h-[480px]"
            key={index}
          >
            {/* image  */}
            <div className="bg-[#fff]">
              {product?.images?.length > 0 && (
                <img
                  src={product?.images[0]}
                  className="h-[270px] w-full 2xl:h-[350px] 2xl:w-full object-contain rounded-t-md"
                  alt="productImage"
                />
              )}
            </div>
            {/* description  */}
            <div className="p-4 text-start">
              <h2 className="font-normal text-gray-600 text-[16px]">
                {product?.name}
              </h2>
              <p className="font-medium text-gray-600 text-[18px] my-[7px]">
                {product?.cat}
              </p>
              <p className="font-semibold text-gray-600 text-[16px]">
                £{product?.price}
              </p>
            </div>
          </div>
          </SwiperSlide>
        ))}

      </Swiper>
    </div>
  );
};

export default ProductSlider;
