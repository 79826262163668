import { Elements } from "@stripe/react-stripe-js";
import Checkout from "../Checkout/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useParams } from "react-router-dom";
// Customize the appearance of Elements using the Appearance API.
const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#ed5f74",
    borderRadius: "20px",
    fontFamily:
      "--body-font-family: -apple-system, BlinkMacSystemFont, sans-serif",
    colorBackground: "#fafafa",
  },
};
const CheckoutForm = () => {
  const { id } = useParams();
  return (
    <div className=" bg-[#fff] flex justify-center items-center py-[40px] pb-[100px]">
      <div className="w-[100%] max-w-[1440px] p-[25px] rounded">
        <Elements
          stripe={loadStripe(
            `pk_live_51O2jGeDjQ73cA7n4Dj2MlMcfaVGWebSOw2oBv8Peo7U88EnF35Dzw5zhVXKA6WfeuhQBC9HKGUDZG9WzehKc8Udx00lZOhL1SG`
          )}
          options={{ clientSecret: id, appearance: appearance }}
        >
          <Checkout clientSecret={id} />
        </Elements>
      </div>
    </div>
  );
};

export default CheckoutForm;
