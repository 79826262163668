import React from "react";
import instagram from "../../../assets/Icons/instagram.svg";
import snapchat from "../../../assets/Icons/snapchat.svg";
import tiktok from "../../../assets/Icons/tiktok.svg";
import logo from "../../../assets/logo.svg";
const Footer = () => {
  return (
    <div className="bg-[#F5F5F5] text-gray-600">
      <footer className="container mx-auto py-8 md:py-10 text-center md:text-left px-4">
        <div className="md:flex md:justify-between lg:items-start md:space-x-8">
          {/* Column 1 */}
          <aside className="hidden lg:block md:w-1/4">
            <img
              className="h-[80px] w-[130px] md:h-[102px] md:w-[234px] md:mr-[110px] ml-4 md:ml-0"
              src={logo}
              alt=""
            />
            <div class="flex">
              <a
                href="https://www.tiktok.com/@3mporiumcentral"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="" class="ml-[60px] w-[25px] h[25px]" src={tiktok} />
              </a>
              <a
                href="https://www.instagram.com/3mporiumcentral/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt=""
                  class="ml-[20px] w-[25px] h[25px]"
                  src={instagram}
                />
              </a>
              <a
                href="https://snapchat.com/t/qv2iAnFu"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="" class="ml-[20px] w-[25px] h[25px]" src={snapchat} />
              </a>
            </div>
          </aside>

          <div className="flex justify-center items-center lg:hidden md:w-1/4">
            <img
              className="h-[100px] w-[230px] md:h-[102px] md:w-[234px] md:mr-[110px] lg:ml-4 md:ml-0"
              src={logo}
              alt=""
            />
            {/* <p className="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p> */}
          </div>

          {/* Column 2 */}
          <nav className="mt-8 md:mt-0 md:w-1/4">
            <header className="font-semibold mb-[15px] text-black">
              About Us
            </header>
            <a
              href="https://emporium-data.s3.eu-west-2.amazonaws.com/Emporium+Central+Return+Policy.pdf"
              color="transparent"
              target="_blank"
              className="block text-sm mb-[8px] cursor-pointer hover:underline"
              rel="noreferrer"
            >
              Return Policy
            </a>
            <a
              href="https://emporium-data.s3.eu-west-2.amazonaws.com/emporium-central-privacy-policy.pdf"
              color="transparent"
              target="_blank"
              className="block text-sm mb-[8px] cursor-pointer hover:underline"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </nav>

          {/* Column 3 */}
          {/* <nav className="mt-8 md:mt-0 md:w-1/4">
            <header className="font-semibold mb-[30px] text-black">
              Customer Care
            </header>
            <a className="block text-sm mb-[30px]">Help Center</a>
            <a className="block text-sm mb-[30px]">Track Your Order</a>
            <a className="block text-sm mb-[30px]">
              Corporate & Bulk Purchasing
            </a>
            <a className="block text-sm mb-[30px]">Returns & Refunds</a>
          </nav> */}

          {/* Column 4 */}
          <nav className="mt-8 md:mt-0 md:w-1/4">
            <header className="font-semibold mb-[15px] text-black">
              Contact Us
            </header>
            {/* <a className="block text-sm mb-[30px]">
              50 North Whatever Blvd, Washington, DC 10501
            </a> */}

            <p className="block text-sm mb-[8px]">
              <strong>Email:</strong>{" "}
              <a
                className="text-[#2691b8]  hover:underline"
                href="mailto:info@emporiumcentral.co.uk"
                rel="noreferrer"
              >
                info@emporiumcentral.co.uk
              </a>
            </p>

            <p className="block text-sm mb-[8px]">
              <strong>Phone:</strong>{" "}
              <a
                className="text-[#2691b8]  hover:underline"
                href="tel:+447914779797"
                rel="noreferrer"
              >
                +44 7914 779797
              </a>
            </p>
            <p className="block text-sm mb-[8px]">
              Built by{" "}
              <strong>
                <a
                  className="text-[#2691b8]  hover:underline"
                  target="_blank"
                  href="https://arturion.io"
                  rel="noreferrer"
                >
                  Arturion
                </a>
              </strong>
            </p>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
